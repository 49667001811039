import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import styled from 'styled-components';
import FormDevis from '../components/FormDevis';
import Testimonials from '../components/Testimonials';
import Zones from '../components/Zones';
import Prestations from '../components/Prestations';

import MainCta from '../components/MainCta';

import FaqSection from '../components/FaqSection';
import Breadcrumb from '../components/Breadcrumb';
import BlogSection from '../components/BlogSection';
import Layout from '../components/Layout';

export const query = graphql`
  query Geneva_images {
    teamImage: file(relativePath: { eq: "serrurier-team.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bikeImage: file(relativePath: { eq: "bike.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    deplacementGratuitImage: file(
      relativePath: { eq: "deplacement-gratuit.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    genevaMap: file(relativePath: { eq: "geneva-map.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    googleImage: file(relativePath: { eq: "google-image.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
const GenevaFaq = [
  {
    id: 1,
    question: 'Combien dois-je payer pour un service de serrurier ?',
    answer:
      'Les tarifs d’un service de serrurier dépendent des prestations dont vous avez besoin. Pour plus de renseignements, contactez-nous dès maintenant.',
  },
  {
    id: 2,
    question: 'Est-ce qu’on peut installer une porte blindée à Genève ?',
    answer:
      'Cela dépend de si vous êtes propriétaire ou locataire.<br/>Dans certaines municipalités genevoises, l’installation d’une porte blindée n’est pas autorisée. Renseignez-vous auprès de votre concierge pour obtenir l’information exacte.<br/>Cependant, dans certains cas, installer une porte blindée est la solution optimale.',
  },
  {
    id: 3,
    question: "Combien dois-je payer pour l'installation d'un coffre-fort ?",
    answer:
      'Le coût dépend bien évidemment de la qualité du coffre que vous voulez installer.<br/>Certains coffres-forts ont un prix plus élevé ou requièrent plus de temps pour être installés.<br/>Expliquez-nous quel type de coffre-fort vous souhaitez installer et nous vous recommandons les divers choix possibles.',
  },
  {
    id: 4,
    question: 'Combien dois-je payer pour un changement de serrure ?',
    answer:
      'Le prix dépend bien évidemment de la qualité de la serrure dont il est question, mais généralement, nos prix commencent dès 120 CHF pour ce service.<br/>Les tarifs peuvent varier selon les marques utilisées.',
  },
  {
    id: 5,
    question: "Ma clé s'est cassée dans la serrure... <br/>que faire ?",
    answer:
      "Ne paniquez pas ! Il est normalement possible de retirer la partie coincée en recollant les 2 morceaux de la clé avec du super-glue. Si cette solution n'est pas efficace, faites appel à nos serruriers. ",
  },
];

const pages = [
  { name: 'Canton de geneve', href: '/serrurier-geneve/', current: true },
];

export default function Geneve({ data }) {
  const {
    bikeImage,
    deplacementGratuitImage,
    teamImage,
    genevaMap,
    googleImage,
  } = data;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Serrurier Genève, meilleurs prix et devis instantané - dépannage 24/7
        </title>
        <meta
          name="description"
          content="Service remarquable et prix imbattable. Super Serrurier est le mieux noté sur Google de tout Genève avec 4.9 étoiles et plus de 230 commentaires"
        />
      </Helmet>
      <div className="relative bg-gray-100 overflow-hidden">
        <MainTownHeader
          className="relative  bg-gray-800 pt-6 pb-16 sm:pb-24 py-16"
          id="main-jumbo"
        >
          <main className="mt-24 mx-auto max-w-7xl px-4 sm:mt-10">
            <div className="text-center">
              <div className="flex justify-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-primarycolor sm:text-5xl md:text-6xl ml-4 sm:text-white">
                  SERRURIER GENEVE
                </h1>
              </div>

              {/* 
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Serrurier</span>
              <span className="block text-white xl:inline">Vaud</span>
            </h1>
              */}
              <h2 className="text-lg lg:text-2xl xl:text-3xl md:block mt-3 text-gray-700 sm:text-white">
                Profitez d’un service de qualité et sans mauvaises surprises
              </h2>
              <p className="text-sm lg:text-lg text-gray-700 sm:text-white mt-4">
                Super Serrurier, le plus fiable du canton de Geneve
              </p>
              {/* 
            <p className="max-w-md mx-auto text-base text-white sm:text-lg md:text-xl md:max-w-3xl">
              Profitez d’un service de qualité et sans mauvaises surprises
            </p>
            */}
            </div>

            <div className="flex justify-center">
              <a
                href="tel:0791304768"
                className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
              >
                <span className="px-3 py-2 text-white text-lg lg:text-xl xl:text-1xl leading-5 tracking-wide bg-primarycolor rounded-full">
                  Appelez-nous
                </span>
                <span className="ml-3 text-lg lg:text-xl xl:text-1xl font-semibold leading-5">
                  079 130 47 68
                </span>
                {/* <!-- Heroicon name: chevron-right --> */}
                <svg
                  className="ml-2 w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <Hr className="hidden md:block" />
            <MainCta />
          </main>
        </MainTownHeader>
        <Breadcrumb pages={pages} />
        <div className="ml-2 md:py-16 bg-white overflow-hidden lg:py-24">
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <div className="relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
              <div className="relative py-12">
                <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                  <h3 className="inline text-primarycolor">
                    SERRURIER GENEVE PRIX
                  </h3>
                  {/*
                <!-- <h2 className="inline">
                - Faites vous dépanner par les meilleurs serruriers de votre ville
                </h2> --> */}
                </div>
                <p className="mt-6 text-lg text-gray-600">
                  Depuis notre création en 1998, nous appliquons une
                  transparence totale des prix. Si vous cherchez un serrurier à
                  Genève, contactez-nous en décrivant votre besoin. Nous vous
                  transmettrons instantanément un devis gratuit et définitif.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  En faisant appel à Super Serrurier, le devis vous sera remis
                  en moins de 15 minutes. De plus, les prix qui vous sont
                  proposés sont fixes et définitifs et demeurent identiques
                  après le passage du serrurier à votre domicile.
                </p>
                <div className="mt-8 sm:flex">
                  <div className="block md:hidden rounded-md shadow">
                    <a
                      href="tel:0791304768"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                    >
                      Appelez-nous
                    </a>
                  </div>
                  <div className="hidden md:block mt-3 sm:mt-0 lg:ml-0">
                    <a
                      href="/contact"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white shadow-sm bg-secondarycolor hover:bg-secondarycolor-light"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 hidden lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <div className="bg-white p-8 shadow-lg rounded-lg z-10">
                  <FormDevis />
                </div>
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="lg:col-start-2">
                  <h3 className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                    <span className="text-primarycolor">
                      Meilleurs serruriers de Genève
                    </span>
                  </h3>
                  <div className="mt-3 text-lg text-gray-600">
                    <p>
                      Expert en serrurerie depuis 1998, Super Serruriers sert
                      plus de 3’000 clients par an avec un taux de satisfaction
                      de plus 98%.
                    </p>
                    <p className="mt-3 text-lg text-gray-600">
                      Nous mettons à votre service nos meilleurs serruriers pour
                      les dépannages d'urgence. D’ailleurs, nous sommes
                      l’entreprise de serrurerie la mieux notée sur Google, avec
                      4.9 étoiles et plus de 220 commentaires.
                    </p>
                  </div>
                </div>

                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <svg
                    className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    ></rect>
                  </svg>
                  <Image
                    className="relative mx-auto"
                    fluid={googleImage.childImageSharp.fluid}
                    fadeIn={false}
                    loading="eager"
                    alt="Meilleur serrurier de Genève"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                  <h3 className="inline text-primarycolor">
                    Super Serrurier: le réseau de serruriers les plus compétents
                  </h3>
                  {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                </div>
                <p className="mt-3 text-lg text-gray-600">
                  Avec plus de 500.000 habitants, la région genevoise est la
                  deuxième plus peuplée de Suisse. Elle est également connue
                  pour son trafic en heure de pointe où il peut être très
                  compliqué d’y circuler. Par conséquent, en cas de cambriolage
                  par exemple, il est essentiel qu’un serrurier rapide
                  intervienne en urgence.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  Heureusement, Super Serrurier met à votre disposition toute
                  son équipe d’experts en dépannage d'urgence en serrurerie. Nos
                  professionnels interviennent dans tout Genève et dans sa
                  périphérie, passant de Carouge, Meyrin, Satigny, Versoix à
                  Thônex.
                </p>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <Image
                  className="relative mx-auto w-4/5"
                  fluid={genevaMap.childImageSharp.fluid}
                  fadeIn={false}
                  loading="eager"
                  alt="Déplacement gratuit et sans engagement"
                />
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>
          </div>
        </div>

        <Testimonials />

        <div className="ml-2 mt-4 md:py-16 bg-gray-50 overflow-hidden lg:py-24">
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-0">
              <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="relative mt-12 lg:mt-0 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="relative">
                    <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                      <h3 className="inline text-primarycolor">
                        Serrurier Genève: éthique et responsable
                      </h3>
                      {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                    </div>
                    <p className="mt-3 text-lg text-gray-600">
                      Notre succès, nous le devons à notre choix méticuleux des
                      meilleurs artisans serruriers dans la région genevoise.
                      Notre responsabilité est de vous garantir un travail de
                      qualité et surtout sans dégâts sur vos portes et serrures.
                    </p>
                    <p className="mt-3 text-lg text-gray-600">
                      Nous formons des équipes hautement qualifiées, un
                      personnel expérimenté, compétent et digne de confiance.
                    </p>
                    <p className="mt-3 text-lg text-gray-600">
                      De plus, nos Super Serruriers se déplacent chez vous
                      gratuitement, pour vous donner un avis d’expert et un
                      devis gratuit. N’attendez pas, appelez-nous !
                    </p>
                    <div className="mt-8 sm:flex">
                      <div className="block md:hidden rounded-md shadow">
                        <a
                          href="tel:0791304768"
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                        >
                          Appelez-nous
                        </a>
                      </div>
                      <div className="hidden md:block mt-3 sm:mt-0 sm:ml-3 lg:ml-0">
                        <a
                          href="/contact"
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-secondarycolor shadow-sm border-gray-200 bg-white hover:bg-gray-200"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    className="mt-10 -mx-4 relative lg:mt-0"
                    aria-hidden="true"
                  >
                    <Image
                      fluid={teamImage.childImageSharp.fluid}
                      fadeIn={false}
                      loading="eager"
                      style={{ borderRaduis: '7px' }}
                      className="relative mx-auto"
                      alt="Serrurier Éthique et responsable"
                    />
                  </div>
                </div>
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-28 lg:items-center mt-12 lg:mt-32">
                  <div className="lg:col-start-2">
                    <h2 className="text-2xl font-extrabold text-primarycolor tracking-tight sm:text-3xl">
                      Rapidité et efficacité
                    </h2>
                    <div className="mt-3 text-lg text-gray-600">
                      <p>
                        Chez Super Serrurier, nous avons opté pour un
                        emplacement stratégique à Genève. Ceci nous permet
                        d’intervenir en moins de 20 minutes même en cas
                        d’urgence.
                      </p>
                      <p className="mt-3 text-lg text-gray-600">
                        Soyez certain que suite à votre appel, un de nos
                        excellents serruriers se déplacera sans tarder à votre
                        adresse.
                      </p>
                      <p className="mt-3 text-lg text-gray-600">
                        Super Serrurier vous garantit bien évidemment une
                        prestation de qualité inégalable.
                      </p>
                    </div>
                  </div>
                  <div className="-mx-4 relative lg:mt-0 lg:col-start-1 mt-12">
                    <svg
                      className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                      width="784"
                      height="244"
                      fill="none"
                      viewBox="0 0 784 404"
                      aria-hidden="true"
                    >
                      <defs>
                        <pattern
                          id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          ></rect>
                        </pattern>
                      </defs>
                      <rect
                        width="784"
                        height="404"
                        fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                      ></rect>
                    </svg>
                    <Image
                      fluid={deplacementGratuitImage.childImageSharp.fluid}
                      fadeIn={false}
                      loading="eager"
                      alt="Serrurier rapide et efficace"
                      className="relative mx-auto"
                    />
                  </div>
                </div>

                <div className="relative mt-12 lg:mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="relative">
                    <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                      <h3 className="inline text-primarycolor">
                        Entreprise éco-responsable
                      </h3>
                      {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                    </div>
                    <p className="mt-3 text-lg text-gray-600">
                      Depuis 2017, super serrurier s’engage pour l’écologie, 30%
                      de nos serruriers se déplacent à vélo électrique. De plus,
                      durant les heures d’embouteillages, cela nous permet
                      d’intervenir encore plus rapidement.
                    </p>
                    <p className="mt-3 text-lg text-gray-600">
                      Ce qui fait probablement de nous les serruriers les plus
                      rapides de la région.
                    </p>
                  </div>

                  <div
                    className="mt-10 -mx-4 relative lg:mt-0"
                    aria-hidden="true"
                  >
                    <Image
                      fluid={bikeImage.childImageSharp.fluid}
                      fadeIn={false}
                      loading="eager"
                      className="relative mx-auto"
                      style={{ borderRaduis: '7px' }}
                      alt="Entreprise éco-responsable"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Zones
          title="Trouver un serrurier à proximité"
          text="Notre réseau serrurier Genève est disponible 24/7 toute l'année, même les jours fériés, dans tout le canton de Genève. Que vous soyez à Carouge, Meyrin, Onex, Satigny, Versoix ou à Thônex, vous trouverez toujours un super serrurier près de chez vous où que vous soyez."
        />
        <Prestations
          title="Nos services"
          description="Super Serrurier vous met à disposition son expertise pour remédier efficacement à vos problèmes de serrurerie. Voici les services que notre réseau de serrurier Genève vous propose"
        />
        <FaqSection content={GenevaFaq} />

        <BlogSection />
      </div>
    </Layout>
  );
}

const MainTownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('https://res.cloudinary.com/dxm0sdgpv/image/upload/v1614176751/super-serrurier/geneva-min_fk0n1e_2_qgle4j.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 767px) {
    background-image: none;
  }
`;

const Hr = styled.hr`
    height: 1.5px;
    width: 15%;
    background: #fff;
    margin: auto;
    margin-top: 2rem;
}
`;
